import styled from "styled-components";

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 124px 0 0px 0;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_small}) {
    margin: 72px 0 0px 0;
  }
`;
