import React from "react";
import SEO from "components/seo";
import styled from "styled-components";
import useHerosTypesData from "shared/Hooks/PageHeaders/useHerosTypesData";
import {useContactCardData} from "shared/Hooks/Contact";
import {SectionWrapper} from "shared/Styles/Wrappers";
import {ContactCards} from "sections/Contact";
import {CenteredTextBox} from "components/TextBoxes";
import {Form, Container, CenteredHeader} from "components/Form";
import {TitleXL} from "components/Typography";
import {BigHero} from "components/BigHero";
import {CONTACT} from "../shared/Types/heros";
import {MapContainer} from "components/MapContainer/mapContainer";
import {CONTACT_IFRAME_NAME} from "components/Form/form";
import googleMapStyles from "components/MapContainer/mapStyles";
import {useSelector} from "react-redux";
import {
    Address,
    AddressColumn,
    AddressText,
    AddressTitle,
    Column, Row,
    Title,
    TitleWrapper
} from "../sections/Footer/styles";

const StyledTextWrapper = styled.div`
    padding-top: 48px;
    margin-top: -48px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        margin: 0;
        padding: 0;
    }
`;

export const ContactWrapper = styled(SectionWrapper)`
    color: #fff;
`;

export const ContactContainer = styled.div`
    max-width: 100vw;
    width: 100%;
    background-color: #0b0b0b;
    overflow: hidden;
`;

const data_footer = {
    phone:    "693 381 393",
    email:    "hello@interactivevision.pl",
    street_1: "ul. Przemysłowa 14B",
    city_1:   "Rzeszów 35-105",
    hours:    "Godziny otwarcia: 09:00 - 17:00",
    company:  <>InteractiveVision Sp. z o.o.</>,
    title_2:  "InteractiveVision Sp. z o.o.",
    nip:      "NIP: 8133839510",
    regon:    "REGON: 386180299",
    krs:      "KRS: 0000843954",
    copyrights:
        "Copyright © 2009 - 2022 | Agencja Interaktywna InteractiveVision - Wszystkie prawa zastrzeżone",
};

const Contact = () => {
    const persons = useContactCardData();
    const data = useHerosTypesData(CONTACT);
    const themeState = useSelector((state) => state.theme.darkMode);

    return (
        <>
            <SEO title={data.title} description={data.seo}/>
            <BigHero data={data}/>
            <SectionWrapper>
                <CenteredTextBox
                    title="Wejdź na naszą orbitę"
                    titleTag="h2"
                    text="Nie jesteśmy obcym przybyszem z kosmosu. Napisz do nas i powierz nam swoją misję, a razem podbijemy pozaziemski świat. Jesteśmy tu dla Ciebie."
                />
                <ContactCards persons={persons}/>
                <Container>
                    <CenteredHeader>
                        <TitleXL>Skontaktuj się z nami!</TitleXL>
                    </CenteredHeader>
                    <StyledTextWrapper id="formularz_kontaktowy">
                        <Form
                            src={process.env.GATSBY_CONTACT_FORM_URL}
                            name={CONTACT_IFRAME_NAME}
                        />
                    </StyledTextWrapper>
                </Container>
            </SectionWrapper>
           {/* <MapContainer
                mapStyle={themeState ? googleMapStyles.dark : googleMapStyles.light}
            />*/}
            <ContactContainer>
                <ContactWrapper>
                    <Row>
                        <Column>
                            <TitleWrapper className="purple">
                                <Title>
                                    Odkrywaj z nami swoje możliwości i&#160;razem sięgnijmy gwiazd
                                </Title>
                            </TitleWrapper>
                        </Column>
                        <Column>
                            <Address>
                                <AddressTitle>{data_footer.title_2}</AddressTitle>
                                <AddressColumn>
                                    <AddressText>{data_footer.street_1}</AddressText>
                                    <AddressText>{data_footer.city_1}</AddressText>
                                    <AddressText>{data_footer.hours}</AddressText>
                                </AddressColumn>
                                <AddressColumn>
                                    <AddressText>{data_footer.nip}</AddressText>
                                    <AddressText>{data_footer.regon}</AddressText>
                                    <AddressText>{data_footer.krs}</AddressText>
                                </AddressColumn>
                            </Address>
                        </Column>
                    </Row>
                </ContactWrapper>
            </ContactContainer>
        </>
    );
};

export default Contact;
