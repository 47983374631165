import { graphql, useStaticQuery } from "gatsby";

const useContactCardPhotos = () => {
  const imgs = useStaticQuery(graphql`
    query {
      MariuszTrzeciak: file(relativePath: { eq: "images/persons/mt.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 274, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      MagdalenaJaremcio: file(relativePath: { eq: "images/persons/mj.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 274, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      PatrycjaBilska: file(relativePath: { eq: "images/persons/pb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 274, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      MichalMaslowski: file(relativePath: { eq: "images/persons/mm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 274, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      GrzegorzSowa: file(relativePath: { eq: "images/persons/gs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 274, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      MonikaSliwa: file(relativePath: { eq: "images/persons/ms.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 274, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      MarzenaKawalerska: file(relativePath: { eq: "images/persons/mka.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 274, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      JakubFurman: file(relativePath: { eq: "images/persons/jf.png" }) {
        childImageSharp {
          fluid(maxWidth: 274, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return imgs;
};

export { useContactCardPhotos };
