import styled from "styled-components";

export const ImageWrapper = styled.div`
  width: 336px;
  height: 336px;
  overflow: hidden;
  border-radius: 50%;
  padding: 24px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  img {
    border-radius: 50%;
    height: 100%;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_small}) {
    width: 280px;
    height: 280px;
    padding: 18px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 240px;
    height: 240px;
    padding: 12px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    width: 200px;
    height: 200px;
    padding: 8px;
  }
`;

export const TextWrapper = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 300px;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;

export const Card = styled.div`
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  padding-bottom: 64px;
  margin-bottom: 64px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    padding-bottom: 48px;
    margin-bottom: 48px;
  }
`;

export const Text = styled.div`
  font-size: 1.2rem;
  line-height: 1.2;
  margin-bottom: 24px;
  margin-top: 6px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 1.2rem;
    margin-bottom: 16px;
  }
`;

export const TextBold = styled(Text)`
  font-weight: 800;
  font-size: 1.8rem;
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 1.6rem;
  }
`;

export const Position = styled(Text)`
  color: ${({ color, theme }) => (color ? color : theme.colors.black)};
  font-weight: 300;
`;

export const TextMargin = styled(Text)`
  color: ${({ color, theme }) => (color ? color : theme.colors.black)};
  margin-top: 24px;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.6;
  max-width: 320px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 18px;
    font-size: 1.2rem;
    width: 100%;
  }
`;

export const Email = styled.a`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.6rem;
  display: inline-block;
  text-decoration: none;
  transition: 0.2s all ease;
  font-weight: 600;
  &:hover {
    color: ${({ theme }) => theme.colors.purple};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 1.4rem;
  }
`;

export const Phone = styled(Email)`
  font-weight: 600;
  margin-top: 6px;
`;
