import React from "react";
import {
  CardDesc,
  CardIcon,
  CardLink,
  CardSpan,
  CardSubTitle,
  CardTitle,
  Icon,
  IconWrapper,
  PlaceCard,
} from "./styles";
import RouteSvg from "assets/images/route.svg";

const MapCard = ({
  companyName,
  companyAddress,
  googleLink,
  routeLink,
  active,
}) => {
  return (
    <PlaceCard className={active ? "" : "hide"}>
      <CardDesc>
        <CardTitle>{companyName}</CardTitle>
        <CardSubTitle>
          Przemysłowa 14b
          <br />
          35-105 Rzeszów
        </CardSubTitle>
        <CardLink href={googleLink} target="_blank" title="Otwórz mapę Google">
          Otwórz mapę Google
        </CardLink>
      </CardDesc>
      <CardIcon href={routeLink} target="_blank" title="Wyznacz trasę">
        <IconWrapper>
          <Icon src={RouteSvg} />
        </IconWrapper>
        <CardSpan>Nawiguj</CardSpan>
      </CardIcon>
    </PlaceCard>
  );
};

export { MapCard };
