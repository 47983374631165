import React from "react";
import {
  Card,
  ImageWrapper,
  TextBold,
  TextMargin,
  TextWrapper,
  Position,
  Email,
  Phone,
} from "./styles";

const ContactCard = ({ person, children }) => {
  const { name, image, position, job, mail, phone } = person;
  return (
    <Card>
      <ImageWrapper>{image}</ImageWrapper>
      <TextWrapper>
        <TextBold>{name}</TextBold>
        <Position>{position}</Position>
        <Email href={`mailto:${mail}`}>{mail}</Email>
        <Phone href={`tel:${phone}`}>{phone}</Phone>
        <TextMargin>{job}</TextMargin>
      </TextWrapper>
      {children}
    </Card>
  );
};

export { ContactCard };
