import React from "react";
import Image from "gatsby-image";
import {useContactCardPhotos} from "shared/Hooks/Contact";

const useContactCardData = () => {
  const imgs = useContactCardPhotos();
  const persons = [
    /*{
      image: (
        <Image
          alt="Jakub Furman"
          fluid={imgs.JakubFurman.childImageSharp.fluid}
        />
      ),
      name: "Jakub Furman",
      position: "Sales Manager",
      job: "Konsultacje w sprawie ofert i pełnego zakresu naszych usług.",
      mail: "j.furman@interactivevision.pl",
      phone: "693 381 393",
    },*/
   /* {
      image: (
        <Image
          alt="Magdalena Jaremcio"
          fluid={imgs.MagdalenaJaremcio.childImageSharp.fluid}
        />
      ),
      name: "Magdalena Jaremcio",
      position: "Office Manager",
      job: "Obsługa administracyjna biura",
      mail: "biuro@interactivevision.pl",
      phone: "791 317 069",
    },*/
    /*{
      image: (
        <Image
          alt="Patrycja Bilska"
          fluid={imgs.PatrycjaBilska.childImageSharp.fluid}
        />
      ),
      name: "Patrycja Bilska",
      position: "Project Manager",
      job:
        "Strony www, Sklepy internetowe, Systemy i aplikacje dedykowane, Portale informacyjne, Identyfikacja wizualna, Projekty graficzne",
      mail: "p.bilska@interactivevision.pl",
      phone: "",
    },*/
    /*{
      image: (
        <Image
          alt="Michał Masłowski"
          fluid={imgs.MichalMaslowski.childImageSharp.fluid}
        />
      ),
      name: "Michał Masłowski",
      position: "Project Manager",
      job:
        "Strony www, Sklepy internetowe, Wdrożenia WordPress, Identyfikacja wizualna, Projekty graficzne",
      mail: "m.maslowski@interactivevision.pl",
      phone: "",
    },*/
    // {
    //   image: (
    //     <Image
    //       alt="Grzegorz Sowa"
    //       fluid={imgs.GrzegorzSowa.childImageSharp.fluid}
    //     />
    //   ),
    //   name: "Grzegorz Sowa",
    //   position: "Project manager",
    //   job:
    //     "Sklepy internetowe na platformie Shoper, Strony www, Kampanie Google Ads, Projekty graficzne, Identyfikacja wizualna, Druk i reklama DTP, Gadżety reklamowe, Reklama zewnętrzna",
    //   mail: "g.sowa@interactivevision.pl",
    //   phone: "533 903 640",
    // },
    {
      image: (
        <Image
          alt="Marzena Kawalerska"
          fluid={imgs.MarzenaKawalerska.childImageSharp.fluid}
        />
      ),
      name: "Marzena Kawalerska",
      position: "Marketing Manager",
      job:
        "Obsługa social media: Facebook, Instagram, Linkedin. Szkolenia social media, konsultacje social media.",
      mail: "m.kawalerska@interactivevision.pl",
      phone: "",
    },
    {
      image: (
        <Image
          alt="Mariusz Trzeciak"
          fluid={imgs.MariuszTrzeciak.childImageSharp.fluid}
        />
      ),
      name: "Mariusz Trzeciak",
      position: "Founder & CEO",
      job: "Kampanie marketingowe, identyfikacja wizualna.",
      mail: "m.trzeciak@interactivevision.pl",
      phone: "",
    },
  ];
  return persons;
};
export {useContactCardData};
