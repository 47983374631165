import React from "react";
import { ContactCard } from "sections/Contact/ContactCards/ContactCard";
import { CardsWrapper } from "./styles";

const ContactCards = ({ persons }) => {
  return (
    <CardsWrapper>
      {persons.map((person, i) => (
        <ContactCard key={i} person={person} />
      ))}
    </CardsWrapper>
  );
};

export { ContactCards };
