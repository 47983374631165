import styled from "styled-components";
import { GlobalWrapper } from "shared/Styles/Wrappers";

export const Container = styled(GlobalWrapper)`
  position: relative;
  height: 650px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    height: 500px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 480px;
  }
`;
