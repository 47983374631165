import React, { useState } from "react";
import PinBar from "assets/images/marker.png";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { Container } from "./styles";
import { MapCard } from "components/MapCard";
import { useRef } from "react";
import uuid from "react-uuid";

let MapContainer = ({ google, mapStyle }) => {
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(!open);
  const ref = useRef();

  const mapLoaded = (mapProps, map) => {
    map.setOptions({
      styles: mapStyle,
    });
  };

  return (
    <Container>
      <Map
        key={uuid()}
        google={google}
        ref={ref}
        zoom={16}
        onReady={(mapProps, map) => mapLoaded(mapProps, map)}
        initialCenter={{
          lat: 50.019281643723374,
          lng: 21.965296269401495,
        }}
        center={{
          lat: 50.019281643723374,
          lng: 21.965296269401495,
        }}
        zoomControl={false}
        scaleControl={false}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
      >
        <MapCard
          active={open}
          companyName="InteractiveVision"
          companyAddress="Przemysłowa 14b, 35-105 Rzeszów"
          googleLink="https://www.google.com/maps/place/InteractiveVision/@50.019146,21.965307,16z/data=!4m5!3m4!1s0x0:0x1d1c19f6baf96f08!8m2!3d50.0191457!4d21.965307?hl=pl"
          routeLink="https://www.google.com/maps/dir//Przemys%C5%82owa+14b,+Rzesz%C3%B3w/@50.0322972,21.9683675,14z/data=!4m8!4m7!1m0!1m5!1m1!1s0x473cfbbf373c267d:0x8d3a5bdaffad9edb!2m2!1d21.9648996!2d50.0189322?hl=pl"
        />
        <Marker
          name={"InteractiveVision"}
          position={{ lat: 50.019255977148, lng: 21.965296269401495 }}
          icon={{
            url: PinBar,
            anchor: new google.maps.Point(18, 18),
            scaledSize: new google.maps.Size(36, 48),
          }}
          onClick={handleOpen}
          style={{ cursor: "pointer" }}
        />
      </Map>
    </Container>
  );
};

MapContainer = GoogleApiWrapper({
  apiKey: "AIzaSyAcFk0sEJvkWS0wttA2buigAlmiBN7gxrg",
})(MapContainer);

export { MapContainer };
