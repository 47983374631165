import styled from "styled-components";
import { TitleL, TitleM } from "../Typography/styles";

export const PlaceCard = styled.section`
  position: absolute;
  top: 30px;
  left: 30px;
  width: 360px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 18px;
  transition: opacity 0.1s, visibility 0.1s;

  &.hide {
    opacity: 0;
    visibility: hidden;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 400px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 350px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    left: 12px;
    right: 12px;
    top: 12px;
    width: auto;
  }
`;

export const CardDesc = styled.div`
  width: calc(100% - 72px);
  a {
    color: ${({ theme }) => theme.colors.purple};
  }
`;

export const CardIcon = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  background-color: ${({ theme }) => theme.colors.purple};
  border-radius: 5px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #4900d2;
  }
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 28px;
    height: 28px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_small}) {
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    width: 20px;
    height: 20px;
  }
`;

export const Icon = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const CardSpan = styled.span`
  color: #fff;
  margin-top: 6px;
  font-size: 12px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.xxs};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    font-size: 10px;
    margin-top: 10px;
  }
`;

export const CardLink = styled.a`
  color: #fff;
  margin-top: 6px;
  font-size: 12px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
  }
`;

export const CardTitle = styled(TitleL)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.xs};
  }
`;

export const CardSubTitle = styled(TitleM)`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;
  margin: 4px 0 4px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 12px;
    margin: 6px 0 12px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_small}) {
    max-width: 120px;
  }
`;
